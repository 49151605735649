/*
Remmy Gallery Teaser 
*/


import * as React from 'react';
import {useBreakpoint} from "gatsby-plugin-breakpoints";
import Link from "gatsby-link";
import { IoMdPlay } from "react-icons/io";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";


// DATA
import products from '../../data/products.js';

//Images
import {bauhaus_sammlung} from "../../ressources/Bauhaus";
    

// CSS
import './teaser.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



const RemmyGallery_Teaser  = ({sid, children, html}) => {
    let productItem = products.items.find(i => i.sid === sid);
    const { t } = useTranslation('remmygallery');
    const breakpoints = useBreakpoint();

    
    if (!productItem) {
        // set defaults
        productItem = {
          sid: "RemmyGallery",
          tags: [
            "AR",
            "HMI",
            "interior",
            "exterior",
            "metaverse",
            "multiplatform",
            "movingplatform",
            "hololens",
            "digitaltwin",
            "case",
          ],
          "video-desktop": "",
          "video-mobile": "",
        };
    }

    //HASHTAGS
    let tags = [];
    for (let i = -1; ++i < productItem.tags.length;) {
        tags.push(
            <span key={i}>
                <span className="raute" />
                <span>{productItem.tags[i]}</span>
                <span> </span>
            </span>
        );
    }


    return (
        <div className="fullWidth grau-bg kasten-block">
           
            <div className="col8" style={{overflow: "hidden"}}>
                 <img src={bauhaus_sammlung} style={{objectFit: "cover"}}/>
            </div>

            <div className="col6 text-block">
                <div className={"content-block"}>
                    <h3>{t("product titel")}</h3>
                    <p className="p-tags">{tags}</p>
                    <p className="product-text">{t("product caption")}</p>
                    <p className="product-text" dangerouslySetInnerHTML={{__html: t('product description')}}></p>
          
                    
                    {children}

                    {productItem.soon ?
                        (<button className={'bt_mehrerfahren grau-bg soon'}>COMIN SOON </button>) 
                        :
                        <Link to={`/projects/${productItem.sid}`}>
                            <button className={'bt_mehrerfahren grau-bg-light'}>{t('Mehr erfahren')} <IoMdPlay className={"bt_mehrerfahren_icon"}/></button>
                        </Link>
                    }
                </div>
            </div>
        </div>
    )
}
export default RemmyGallery_Teaser 
