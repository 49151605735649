import * as React from "react";
import { Helmet } from "react-helmet";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
} from "gatsby-plugin-react-i18next";

//Components
import Layout from "../components/layout";
import Abschnitt from "../components/abschnitt";
import Header from "../components/header/header";
import { identity as Identity } from "../components/identity";
import Trenner from "../components/trenner";
import FlyingContactClick from "../components/contactFlyingButton_click.js";
import SEO from "../components/generics/seo";
import HR from "../components/generics/HR";

//Teaser
import Teaser from "../components/teaser/teaser";
import AtmosphericData_Teaser from "../components/teaser/AtmosphericData_Teaser";
import Bauhaus_Teaser from "../components/teaser/Bauhaus_Teaser";
import BuntAR_Teaser from "../components/teaser/BuntAR_Teaser";
import BuntVR_Teaser from "../components/teaser/BuntVR_Teaser";
import CatTreeConfigurator_Teaser from "../components/teaser/CatTreeConfigurator_Teaser";
import CompanionOrb_Teaser from "../components/teaser/CompanionOrb_Teaser";
import Feininger_Teaser from "../components/teaser/Feininger_Teaser";
import HaengendeGaerten_Teaser from "../components/teaser/HaengendeGaerten_Teaser";
import ImmersivesErzaehlen_Teaser from "../components/teaser/ImmersivesErzaehlen_Teaser";
import Moodpausch_Teaser from "../components/teaser/Moodpausch_Teaser";
import RemmyGallery_Teaser from "../components/teaser/RemmyGallery_Teaser";
import Spiegelkabinett_Teaser from "../components/teaser/Spiegelkabinett_Teaser";
import XRMobiliy_Teaser from "../components/teaser/XRMobility_Teaser";


// STYLE
import "../components/contactFlyingButton.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { bild1, bild2, bild3, bild4, bild5 } from "../images/buero";
import { graphql } from "gatsby";

const helm = {
  title: "DENKUNDMACH - Multimedia Designagentur",
  url: "https://denkundmach.de",
  description: "",
};

const Projects = (location) => {
  const breakpoints = useBreakpoint();
  const { t } = useTranslation("translation");
  const { language } = useI18next();

  return (
    <>
      {/* Schreibt SEO Optimierungen für die Seite (Titel, Canonical-Link, Beschreibung */}
      {/* LD/JSON bei Bedarf: Product oder ContactPoint von schema.org */}
      <SEO
        lang={language}
        title={t("meta title")}
        canonical={t("meta canonical")}
        description={t("meta description")}
        productLDJSON={true}
      />

      <Layout>
        <Header location={location} />

        <Trenner verysmall={!breakpoints.md} />
        <Abschnitt line={true} headline={t("projects_headline")}>

          
          <Trenner verysmall={!breakpoints.md} />
          <XRMobiliy_Teaser/> 

          <Trenner verysmall={!breakpoints.md} />
          <AtmosphericData_Teaser />
         
          <Trenner verysmall={!breakpoints.md} />
          <Bauhaus_Teaser />

          <Trenner verysmall={!breakpoints.md} />
          <BuntAR_Teaser />
   
          <Trenner verysmall={!breakpoints.md} />
          <BuntVR_Teaser /> 

          <Trenner verysmall={!breakpoints.md} />
          <CatTreeConfigurator_Teaser/>

          <Trenner verysmall={!breakpoints.md} />
          <CompanionOrb_Teaser/>
          
          <Trenner verysmall={!breakpoints.md} />
          <Feininger_Teaser/>
          
          <Trenner verysmall={!breakpoints.md} />
          <HaengendeGaerten_Teaser/>
          
          <Trenner verysmall={!breakpoints.md} />
          <ImmersivesErzaehlen_Teaser/>
          
          <Trenner verysmall={!breakpoints.md} />
          <Moodpausch_Teaser/>
          
          <Trenner verysmall={!breakpoints.md} />
          <RemmyGallery_Teaser/>

          <Trenner verysmall={!breakpoints.md} />
          <Spiegelkabinett_Teaser/>


          
        </Abschnitt>

        <FlyingContactClick />
      </Layout>
    </>
  );
};

export default Projects;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
