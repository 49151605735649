/*
XR_Mobility Teaser (Projekteseite)
*/

import * as React from 'react';
import {useBreakpoint} from "gatsby-plugin-breakpoints";
import Link from "gatsby-link";
import { IoMdPlay } from "react-icons/io";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";


// DATA
import products from '../../data/products.js';

//Images
import {bauhaus_sammlung} from "../../ressources/Bauhaus";
import videodesktop from "../../videos/teaser/XR-Experience_Slideshow/XR-Experience_Slideshow_DENKUNDMACH_web_1280.mp4";
import videomobile from "../../videos/teaser/XR-Experience_Slideshow/XR-Experience_Slideshow_DENKUNDMACH_web_640.mp4";

// HELPER
import InlineMobileVideo from "../generics/inlineVideo";

// CSS
import './teaser.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



const XRMobility_Teaser  = ({sid, children, html}) => {
    let productItem = products.items.find(i => i.sid === sid);
    const { t } = useTranslation('XR-Mobility');
    const breakpoints = useBreakpoint();

    
    if (!productItem) {
        // set defaults
        productItem = {
          sid: "XR-Mobility",
          tags: [
            "AR",
            "HMI",
            "interior",
            "exterior",
            "metaverse",
            "multiplatform",
            "movingplatform",
            "hololens",
            "digitaltwin",
            "case",
          ],
          "video-desktop": "",
          "video-mobile": "",
        };
    }

    //HASHTAGS
    let tags = [];
    for (let i = -1; ++i < productItem.tags.length;) {
        tags.push(
            <span key={i}>
                <span className="raute" />
                <span>{productItem.tags[i]}</span>
                <span> </span>
            </span>
        );
    }

    let leftBoxContent;
    // Reverse mobile check !FIX! da sonst mobile beide video rendered, da breakpoints zu spät initialisiert
    if (breakpoints.xs === undefined || breakpoints.sm === undefined) {
        leftBoxContent = (<></>);
    }
    else if (breakpoints.sm === true || breakpoints.xs === true) {
        leftBoxContent = (
           // InlineMobileVideo(productItem['vid-mobile'])
            InlineMobileVideo(videomobile)
        );
    }
    else if (breakpoints.sm === false && breakpoints.xs === false) {
        leftBoxContent = (
            <video
                className="video"
                autoPlay="autoplay"
                controls=""
                loop
                muted
                src={videodesktop}
            />
        );
    }


    return (
        <div className="fullWidth grau-bg kasten-block">
           
            <div className="col8" style={{overflow: "hidden"}}>
            {leftBoxContent}
            </div>

            <div className="col6 text-block">
                <div className={"content-block"}>
                    <h3>{t("product title")}</h3>
                    <p className="p-tags">{tags}</p>
                    <p className="product-text">{t("product caption")}</p>
                    <p className="product-text" dangerouslySetInnerHTML={{__html: t('product description short')}}></p>
          
                    
                    {children}

                    {productItem.soon ?
                        (<button className={'bt_mehrerfahren grau-bg soon'}>COMIN SOON </button>) 
                        :
                        <Link to={`/projects/${productItem.sid}`}>
                            <button className={'bt_mehrerfahren grau-bg-light'}>{t('Mehr erfahren')} <IoMdPlay className={"bt_mehrerfahren_icon"}/></button>
                        </Link>
                    }
                </div>
            </div>
        </div>
    )
}
export default XRMobility_Teaser 
